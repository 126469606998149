import { FileUploader, Label, Select } from '@Components';
import { Col, Row } from 'reactstrap';
import { observer } from 'mobx-react';
import { LookupStringValueModel, TestDocumentType } from '@Models';
import { FormModel } from './TestDocumentFormModel';
import { enumHelper } from '@Helpers';
import { useEffect, useState } from 'react';

type TestDocumentFormProps = {
    form: FormModel;
    onDocumentTypeChanged?: (value: TestDocumentType) => void;
    showDocumentTypeSelector: boolean;
    allowedTestDocumentTypes: TestDocumentType[];
};

function TestDocumentFormComponent({ form, onDocumentTypeChanged, showDocumentTypeSelector, allowedTestDocumentTypes }: TestDocumentFormProps) {
    const [documentTypeOptions, setDocumentTypeOptions] = useState<LookupStringValueModel[]>();
    useEffect(() => {
        const options: LookupStringValueModel[] = allowedTestDocumentTypes.map(x => {
            return { label: enumHelper.TestDocumentTypeLabels[x], value: x }
        });
        setDocumentTypeOptions(options);
    }, [allowedTestDocumentTypes]);

    const handleFileChanged = (file: File) => {
        form.documentFile = file;
    };

    const changeReportTypeHandler = (m: LookupStringValueModel | null) => {
        const selectedReportType = m?.value as TestDocumentType ?? TestDocumentType.FullReport;
        onDocumentTypeChanged?.(selectedReportType);
    };

    return (
        <div className="tms-form-wrapper">
            {showDocumentTypeSelector && <Row>
                <Col sm="4">
                    Report Type
                </Col>
                <Col>
                    <Select<LookupStringValueModel>
                        options={documentTypeOptions}
                        defaultValue={documentTypeOptions?.find(x => x.value === TestDocumentType.FullReport)}
                        onChange={changeReportTypeHandler} />
                </Col>
            </Row>
            }
            <Row>
                <Col sm="4">
                    <Label text={'File'} />
                </Col>
                <Col>
                    <FileUploader
                        onFileChanged={handleFileChanged}
                        label={form.uploadedFileName}
                    />
                </Col>
            </Row>
        </div>
    )
}

export const TestDocumentForm = observer(TestDocumentFormComponent);
