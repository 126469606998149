export class FileFormat {
    public static readonly XLS: string = '.xls';
    public static readonly XLSX: string = '.xlsx';
    public static readonly DOC: string = '.doc';
    public static readonly DOCX: string = '.docx';
    public static readonly PDF: string = '.pdf';
    public static readonly PNG: string = '.png';
    public static readonly GIF: string = '.gif';
    public static readonly JPEG: string = '.jpeg';
    public static readonly JPG: string = '.jpg';
    public static readonly ZIP: string = '.zip';
}