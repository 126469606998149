import * as React from 'react';
import styles from './file-uploader.module.scss';
import { CustomInput } from 'reactstrap'
import { modalService } from '../../Modal/ModalService';
import { attachmentConfigStore } from '@GlobalStores';

export type FileUploaderProps = {
    onFileChanged: (file: File) => void;
    disabled?: boolean;
    label?: string | JSX.Element | null;
    accept?: string[];
    id?: string;
    maxFileSizeInMb?: number;
    innerText?: string;
};

export function FileUploader({ onFileChanged, disabled, label, accept, id, maxFileSizeInMb, innerText }: FileUploaderProps) {
    const acceptedFileFormats = accept ?? attachmentConfigStore.config.allowedAttachmentFileExtensions;
    const maxFileSize: number = 1024 * 1024 * (maxFileSizeInMb ?? 500);
    const inputRef = React.useRef<HTMLInputElement>(null);

    React.useEffect(() => {
        if (!innerText) return;

        setInnerText(innerText);
    }, [innerText]);

    const changeFileHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) return;
        const files = Array.from(event.target.files);

        if (files && files.length) {
            const byFormat = (file: File) => {
                const fileExtension = file.name.slice(file.name.lastIndexOf('.')).toLocaleLowerCase();
                return acceptedFileFormats.includes(fileExtension);
            };
            const bySize = (file: File) => file.size <= maxFileSize;

            const acceptedFiles = files.filter(byFormat).filter(bySize);
            if (acceptedFiles.length < files.length) {
                modalService.showError(`Wrong file format or size > ${maxFileSizeInMb ?? 500}MB`);
                return;
            }

            if (acceptedFiles.length) {
                onFileChanged(acceptedFiles[0]);
            }
        }
    };

    const setInnerText = (value: string) => {
        const labelElement = inputRef.current?.nextElementSibling;
        if (!labelElement) return;

        labelElement.textContent = value;
    }

    return (
        <>
            {label && <div style={{ fontSize: '12px' }}>{label}</div>}
            <CustomInput
                innerRef={inputRef}
                className={styles.fileInput}
                type="file"
                name="file"
                id={id ?? "fileUpload"}
                accept={acceptedFileFormats.join(',')}
                onChange={changeFileHandler}
                disabled={disabled}
            />
        </>
    );
}
