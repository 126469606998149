import { RefObject, useCallback, useEffect, useMemo, useReducer, useRef, useState } from "react";
import { AxiosPromise, AxiosResponse } from "axios";
import { apiClient } from "@Models";
import { DataGrid } from "devextreme-react";
import { configurationStore } from "@GlobalStores";
import { PrincipalConfig } from "@AppConstants";
import { GridState } from "../DxDataGridTypes";
import { Column as ColumnType } from '@Pages/Components/CustomColumnChooser/types';
import { OptionChangedEvent } from "devextreme/ui/data_grid";

export function useDxDatagridStore<T>(loadOptionsApi?: () => PromiseLike<AxiosResponse<T[]>> | AxiosPromise<T[] | T>, gridRef?: RefObject<DataGrid<T>>): [T[], () => void,  (value: (((data: T[]) => T[]) | T[])) => void] {
    const [gridOptions, setGridOptions] = useState<T[]>([]);

    const loadData = useCallback(async () => {
        if (!loadOptionsApi) {
            setGridOptions([]);
        } else {
            const fn = loadOptionsApi.bind(apiClient);
            try {
                gridRef?.current?.instance.beginCustomLoading('Loading...');
                const { data } = await fn();
                if (Array.isArray(data)) {
                    setGridOptions(data);
                }
                else {
                    setGridOptions([data]);
                }

            } finally {
                gridRef?.current?.instance.endCustomLoading();
            }
        }
    }, [loadOptionsApi, gridRef]);

    useEffect(() => {
        loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return [gridOptions, loadData, setGridOptions];
}

export function useDxDatagridStoreWithTracking<T>(loadOptionsApi?: () => AxiosPromise<T[]>, gridRef?: RefObject<DataGrid<T>>): [T[], () => void, (value: (((data: T[]) => T[]) | T[])) => void] {
    const [options, setOptions] = useState<T[]>([]);

    const loadData = useCallback(async () => {
        if (!loadOptionsApi) {
            setOptions([]);
        } else {
            const fn = loadOptionsApi.bind(apiClient);
            try {
                gridRef?.current?.instance.beginCustomLoading('Loading...');
                const { data } = await fn();
                setOptions(data);
                return data;
            } finally {
                gridRef?.current?.instance.endCustomLoading();
            }
        }
    }, [loadOptionsApi, gridRef]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    return [options, loadData, setOptions];
}

export function useDxDatagridStoreEnsuringLatestRequest<T>(loadOptionsApi?: () => AxiosPromise<T[]>, gridRef?: RefObject<DataGrid<T>>): [T[], () => void, (value: (((data: T[]) => T[]) | T[])) => void] {
    const [options, setOptions] = useState<T[]>([]);
    const lastRequestTimestampRef = useRef<number>(0);

    const loadData = useCallback(async () => {
        if (!loadOptionsApi) {
            setOptions([]);
        } else {
            const fn = loadOptionsApi.bind(apiClient);
            const currentRequestTimestamp = Date.now();
            lastRequestTimestampRef.current = currentRequestTimestamp;

            try {
                gridRef?.current?.instance.beginCustomLoading('Loading...');
                const { data } = await fn();
                if (currentRequestTimestamp >= lastRequestTimestampRef.current) {
                    setOptions(data);
                    return data;
                }
            } finally {
                if (currentRequestTimestamp >= lastRequestTimestampRef.current)
                {
                    gridRef?.current?.instance.endCustomLoading();
                }
            }
        }
    }, [loadOptionsApi, gridRef]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    return [options, loadData, setOptions];
}

export function useDxDataGridReloadEffect(reload: () => void) {
    const [mount, setMount] = useState(true);

    useEffect(() => {
        if (!mount) reload();
        if (mount) setMount(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reload]);
}

function columnsStateReducer(state: ColumnType[], changes: ColumnType[]) {
    if (changes && Object.keys(changes)?.length === 0) return state;

    return !!changes?.length ? [...changes] : !!state?.length ? [...state] : [];
}

export type StateStoringOptions<T> = {
    userConfigGroup: string | null;
    gridRef: React.RefObject<DataGrid<T, unknown>>;
    gridOptions: T[];
    parentColumnNames?: string[];
    defaultHiddenColumnNames?: string[];
    getIsColumnVisibleByDefault?: (column: ColumnType) => boolean;
}

export function useDxDatagridStateStoring<T>({ userConfigGroup, gridRef, gridOptions, parentColumnNames, defaultHiddenColumnNames, getIsColumnVisibleByDefault }: StateStoringOptions<T>) {
    const [columns, setColumnsState] = useReducer(columnsStateReducer, []);

    const getGridState = () => {
        return gridRef.current?.instance.state() as GridState;
    };

    const setGridState = (state: GridState | null) => {
        gridRef.current?.instance.state(state);
    };

    const getDefaultColumnVisibility = (column: ColumnType, newHiddenColumnNames?: string[]) => {
        let visible = true;

        const hiddenColumnNames: string[] = [];
        if (defaultHiddenColumnNames) hiddenColumnNames.push(...defaultHiddenColumnNames);
        if (newHiddenColumnNames) hiddenColumnNames.push(...newHiddenColumnNames);
        
        if (hiddenColumnNames) visible = !hiddenColumnNames.find(name => name === column.name);
        if (getIsColumnVisibleByDefault) visible = getIsColumnVisibleByDefault(column);

        return visible;
    };

    const getColumnOptions = () => {
        let columns = [] as ColumnType[];

        const columnCount = gridRef.current?.instance.columnCount() || 0;
        for (let i = 0; i < columnCount; i++) {
            columns.push(gridRef.current?.instance.columnOption(i));
        }

        return columns;
    };

    const getDefaultColumns = (newHiddenColumnNames?: string[]) => {
        const columnOptions = getColumnOptions();

        const cols = columnOptions.map(col => {
            col.visible = getDefaultColumnVisibility(col, newHiddenColumnNames);

            return col;
        });

        const resultCols = cols.map(col => {
            const isParent = col.children?.length || (parentColumnNames?.find(parentName => parentName === col.name));
            if (isParent) {
                col.visible = cols.some(childCol => (childCol.ownerBand === col.index || col.visibleIndex === childCol.parentId) && childCol.visible);
            }

            return col;
        });

        return resultCols;
    };

    useEffect(() => {
        if (!userConfigGroup || !gridOptions.length) return;

        const fetchConfig = async () => {
            const configValue = await configurationStore.get(userConfigGroup, PrincipalConfig.GridStateKey);

            let gridState: GridState;
            gridState = JSON.parse(configValue!);

            if (gridRef?.current) {
                const state = getGridState();

                if (!gridState?.columns?.length) {
                    const cols = getDefaultColumns();

                    configurationStore.set(userConfigGroup, PrincipalConfig.GridStateKey, JSON.stringify({ ...state, columns: cols }));
                    setColumnsState(cols);
                }
                else {
                    let columnOptions = getColumnOptions();
                    const gridStateColumns = gridState?.columns as ColumnType[];

                    columnOptions.forEach(col => {
                        gridStateColumns.forEach(c => {
                            if (c.name === col.name) {
                                col.visible = c.visible;
                                col.visibleIndex = c.visibleIndex;
                            }
                        });
                    });

                    const newColumns = columnOptions
                        .filter(col => {
                            for (let i = 0; i < gridStateColumns.length; i++) {
                                const gridStateColumnsItem = gridStateColumns[i];
                                if (!gridStateColumnsItem.name || col.name === gridStateColumnsItem.name) {
                                    return false;
                                }
                            }

                            return true;
                        })
                        .map(col => {
                            col.visible = getDefaultColumnVisibility(col);

                            return col;
                        })

                    const filteredGridStateColumns = [...gridStateColumns.filter((col) => {
                        for (let i = 0; i < columnOptions.length; i++) {
                            const columnsListItem = columnOptions[i];
                            if (col.name === columnsListItem.name) {
                                return true;
                            }
                        }
                        return false;
                    }), ...newColumns];

                    const resultColumns = filteredGridStateColumns.map((item) => {
                        if (item?.children?.length || (parentColumnNames?.find(parentName => parentName === item.name))) {
                            item.visible = filteredGridStateColumns.some(col => (col.ownerBand === item.index || item.visibleIndex === col.parentId) && col.visible);
                        }
                        return item;
                    });

                    const items = [...resultColumns].filter(item => !item?.ownerBand);
                    const parents = [...items.sort((a, b) => (a.visibleIndex > b.visibleIndex) ? 1 : -1)];

                    for (let i = 0; i <= parents.length; i++) {
                        if (parents[i]?.hasColumns) {
                            const childrenList = resultColumns.filter(it => it.ownerBand === parents[i].index);
                            const children = [...childrenList].sort((a, b) => (a.visibleIndex > b.visibleIndex) ? 1 : -1);

                            parents[i].children = children;
                        }
                    }
                    const result = [...parents];

                    for (let i = 0, lastIndex = 0; i < result.length; i++) {
                        if (i === lastIndex) {
                            if (!!result[i]?.children?.length) {
                                result[i]?.children?.forEach((option: any, index: number) => {
                                    result.splice(lastIndex + 1 + index, 0, option);
                                });
                                lastIndex += (result[i].children?.length || 0) + 1;
                            }
                            else {
                                lastIndex++;
                            }
                        }
                    }

                    result.forEach((el, index) => {
                        if (el?.children?.length) {
                            result.forEach(it => {
                                if (it.ownerBand === el.index) {
                                    it.ownerBand = index;
                                }
                            })
                        }
                        el.index = index;
                    });

                    configurationStore.set(userConfigGroup, PrincipalConfig.GridStateKey, JSON.stringify({
                        ...state,
                        columns: result
                    }));

                    setColumnsState(result);
                    setGridState({
                        ...state,
                        columns: result
                    });
                }
            }
        }

        fetchConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gridOptions, parentColumnNames, defaultHiddenColumnNames, userConfigGroup, gridRef]);

    const applyChanges = useCallback(async (changes) => {
        if (!userConfigGroup) return;

        const scrollElement = gridRef.current?.instance.getScrollable();
        const scrollParams = scrollElement?.scrollOffset();

        const configValue = await configurationStore.get(userConfigGroup, PrincipalConfig.GridStateKey);
        let gridState: GridState;
        gridState = JSON.parse(configValue!);

        const gridStateWithChanges = { ...gridState, columns: changes };

        await configurationStore.set(userConfigGroup, PrincipalConfig.GridStateKey, JSON.stringify(gridStateWithChanges));
        setColumnsState({ ...changes });

        setGridState(gridStateWithChanges);
        gridRef?.current?.instance.refresh();

        // need to add a slight delay before you use the scrollTo method
        setTimeout(() => {
            scrollElement?.scrollTo(scrollParams);
        }, 800);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gridRef, userConfigGroup, setColumnsState]);

    const clearConfig = useCallback(async (newHiddenColumnNames?: string[]) => {
        if (!userConfigGroup) return;

        gridRef.current?.instance.clearFilter();
        gridRef.current?.instance.clearSorting();
        gridRef.current?.instance.clearGrouping();
        setGridState(null);

        setTimeout(async () => {
            const gridState = getGridState();
            const defaultColumns = getDefaultColumns(newHiddenColumnNames);

            const newGridState = { ...gridState, columns: defaultColumns };
            await configurationStore.set(userConfigGroup, PrincipalConfig.GridStateKey, JSON.stringify(newGridState));

            setColumnsState(defaultColumns);
        }, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gridRef, userConfigGroup]);

    const clearFilters = useCallback(() => {
        gridRef.current?.instance.clearFilter();
        gridRef.current?.instance.clearSorting();

        const state = getGridState();

        state.searchText = '';
        setGridState(state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gridRef]);

    const handleOptionChanged = useCallback((event: OptionChangedEvent) => {
        if (!userConfigGroup) return;

        let isColumnReordering = false;
        let isFilterValue = false;

        if (event.fullName.includes('visibleIndex')) {
            isColumnReordering = true;
        }
        if (event.fullName.includes('filterValue')) {
            isFilterValue = true;
        }

        if (isColumnReordering || isFilterValue) {
            const state = getGridState();
            const columnOptions = getColumnOptions();

            configurationStore.set(userConfigGroup, PrincipalConfig.GridStateKey, JSON.stringify({ ...state, columns: columnOptions }));
            setColumnsState(columnOptions);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userConfigGroup])

    const getColumnVisibility = (columnName: string) => {        
        return columns.find((col: any) => col.name === columnName)?.visible ?? true;
    };

    const columnVisibility = useMemo(() => {
        const cols: { [key: string]: boolean } = {};

        columns.forEach(column => {
            if (column.name) cols[column.name] = column.visible;
        });

        return cols;
    }, [columns]);

    return {
        columns,
        columnVisibility,
        getColumnVisibility,
        applyChanges,
        clearConfig,
        clearFilters,
        handleOptionChanged
    }
}
