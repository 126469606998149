/* eslint-disable @typescript-eslint/no-explicit-any */
import {DateTimeService} from '@Services';
import {DateTime} from '@AppConstants';
import {
    isFloatNumber as isFloatNumberValid,
    isNumberFractionalPartIsLessThanOrEqual as isNumberFractionalPartIsLessThanOrEqualValid
} from './Validators/validators';

type ValidateFunction = (object: unknown) => string | undefined;

export type ValidatioRule = {
    fieldName: string;
    name?: string;
    validateFunction: ValidateFunction;
};

/*Validation Rules*/
export function isRequired(msg?: string) {
    return function (target: unknown, name: string) {

        const validation: ValidatioRule = {
            fieldName: name,
            name: 'isRequired',
            validateFunction: (obj: any) => {
                const value = obj[name];
                const invalid = value === undefined || value === null || value === '' || (typeof value === 'string' && !value.trim());
                return invalid ? (msg || 'Field ' + getDisplayName(obj, name) + ' cannot be empty') : undefined;
            }
        };

        addValidation(target, name, validation);
    };
}

export function isRequiredWhen(compareName: string, compareValue: unknown, msg?: string) {
    return function (target: unknown, name: string) {

        const validation = {
            fieldName: name,
            name: 'isRequiredWhen',
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            validateFunction: (obj: any) => {
                const shouldValidate = obj[compareName] === compareValue;
                if (!shouldValidate) {
                    return undefined
                }
                const value = obj[name];
                const invalid = value === undefined || value === null || value === '' || (typeof value === 'string' && !value.trim());
                return invalid ? (msg || 'Field ' + getDisplayName(obj, name) + ' cannot be empty') : undefined;
            }
        };

        addValidation(target, name, validation);
    };
}

export function isDate(msg?: string) {
    const regEx = /^\s*(3[01]|[12][0-9]|0?[1-9])\s(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s(\d{4})\s*$/;
    return function (target: unknown, name: string) {

        const validation = {
            fieldName: name,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            validateFunction: (obj: any) => {
                const isValid = (!obj[name] || (regEx.test(obj[name]) && DateTimeService.isValidDate(DateTimeService.parseUiDate(obj[name]))));
                return isValid ? undefined : (msg || 'Field ' + getDisplayName(obj, name) + ' should have format ' + DateTime.viewDateFormat);
            }
        };

        addValidation(target, name, validation);
    };
}

export function isDateAfter(compareName: string, format?:string, msg?: string) {
    return function (target: any, name: string) {

        const validation = {
            fieldName: name,
            validateFunction: (obj: any) => {
                const originDate = DateTimeService.parse(obj[name], format? format : DateTime.viewDateFormat);
                const compareDate = DateTimeService.parse(obj[compareName], format? format : DateTime.viewDateFormat);
                const isValid = !DateTimeService.isValidDate(originDate) || !DateTimeService.isValidDate(compareDate) || originDate >= compareDate;
                return isValid ? undefined : (msg || getDisplayName(obj, name) + ' should be later than ' + getDisplayName(obj, compareName));
            }
        };

        addValidation(target, name, validation);
    };
}

export function isDateBefore(compareName: string, format?:string, msg?: string) {
    return  function (target: any, name: string) {

        const validation = {
            fieldName: name,
            validateFunction: (obj: any) => {
                const originDate = DateTimeService.parse(obj[name], format? format : DateTime.viewDateFormat);
                const compareDate = DateTimeService.parse(obj[compareName], format? format : DateTime.viewDateFormat);
                const isValid = !DateTimeService.isValidDate(originDate) || !DateTimeService.isValidDate(compareDate) || originDate <= compareDate;
                return isValid ? undefined : (msg || getDisplayName(obj, name) + ' should be earlier than ' + getDisplayName(obj, compareName));
            }
        };

        addValidation(target, name, validation);
    };
}

export function isSameAs(compareName: string, format?:string, msg?: string) {
    return  function (target: any, name: string) {

        const validation = {
            fieldName: name,
            validateFunction: (obj: any) => {
                const isValid = obj[name] === obj[compareName];
                return isValid ? undefined : (msg || getDisplayName(obj, name) + ' should be the same as ' + getDisplayName(obj, compareName));
            }
        };

        addValidation(target, name, validation);
    };
}

export function isNotSameAs(compareName: string, format?: string, msg?: string) {
    return function (target: any, name: string) {

        const validation = {
            fieldName: name,
            validateFunction: (obj: any) => {
                const isValid = obj[name] !== obj[compareName];
                return isValid ? undefined : (msg || getDisplayName(obj, name) + ' should not be the same as ' + getDisplayName(obj, compareName));
            }
        };

        addValidation(target, name, validation);
    };
}

export function isTime(msg?: string) {
    const regEx = /^(([0-1][0-9])|(2[0-3])):[0-5][0-9]$/;
    return  function (target: any, name: string) {

        const validation = {
            fieldName: name,
            validateFunction: (obj: any) => {
                const isValid = (!obj[name] || (regEx.test(obj[name])));
                return isValid ? undefined : (msg || 'Field ' + getDisplayName(obj, name) + ' should have format ' + DateTime.timeFormat.toUpperCase());
            }
        };

        addValidation(target, name, validation);

    };
}

export function isDateTime(msg?: string) {
    const regEx = /^\s*(3[01]|[12][0-9]|0?[1-9])\.(1[012]|0?[1-9])\.((?:19|20)\d{2})[ ](([0-1][0-9])|(2[0-3])):[0-5][0-9]$/;
    return  function (target: any, name: string) {

        const validation = {
            fieldName: name,
            validateFunction: (obj: any) => {
                const isValid = (!obj[name] || (regEx.test(obj[name]) && DateTimeService.isValidDate(DateTimeService.parseUiDateTime(obj[name]))));
                return isValid ? undefined : (msg || 'Field ' + getDisplayName(obj, name) + ' should have format ' + DateTime.viewFullFormat.toUpperCase());
            }
        };

        addValidation(target, name, validation);

    };
}

export function hasMaxLength(length: number, msg?: string) {
    return  function (target: any, name: string) {

        const validation = {
            fieldName: name,
            validateFunction: (obj: any) => {
                const isValid = (!obj[name] || obj[name].toString().length <= length);
                return isValid ? undefined : (msg || 'Field ' + getDisplayName(obj, name) + ' should be less than or equal to ' + length + ' characters');
            }
        };

        addValidation(target, name, validation);

    };
}

export function isRange(min: number, max: number, msg?: string) {
    return  function (target: any, name: string) {

        const validation = {
            fieldName: name,
            validateFunction: (obj: any) => {
                const number = Number(obj[name]);
                const isValid = (!obj[name] || (!isNaN(number) && number >= min && number <= max));
                return isValid ? undefined : (msg || 'Field ' + getDisplayName(obj, name) + ' is out of range from ' + min + ' to ' + max);
            }
        };

        addValidation(target, name, validation);

    };
}

export function isDateInRange(start: string, end: string, format?: string, msg?: string) {
    return function (target: any, name: string) {

        const validation = {
            fieldName: name,
            validateFunction: (obj: any) => {
                // TODO: pass dates not strings
                const originDate = DateTimeService.parse(obj[name], format ? format : DateTime.viewDateFormat);
                const startDate = DateTimeService.parse(obj[start], format ? format : DateTime.viewDateFormat);
                const endDate = DateTimeService.parse(obj[end], format ? format : DateTime.viewDateFormat);

                if (!DateTimeService.isValidDate(originDate))
                    return undefined;

                const isStartValid = start && DateTimeService.isValidDate(startDate) ? DateTimeService.isSameDate(originDate, startDate) || DateTimeService.isAfterDate(originDate, startDate) : true;
                const isEndValid = end && DateTimeService.isValidDate(endDate) ? DateTimeService.isSameDate(originDate, endDate) || DateTimeService.isBeforeDate(originDate, endDate) : true;

                if (!isStartValid)
                    return (msg || getDisplayName(obj, name) + ' should be later or equal to ' + obj[start]);

                if (!isEndValid)
                    return (msg || getDisplayName(obj, name) + ' should be earlier or equal to ' + obj[end]);

                return undefined;
            }
        };

        addValidation(target, name, validation);
    };
}

export function isRegEx(regEx: RegExp, msg?: string) {
    return  function (target: any, name: string) {

        const validation = {
            fieldName: name,
            validateFunction: (obj: any) => {
                const isValid = (!obj[name] || regEx.test(obj[name]));
                return isValid ? undefined : (msg || 'Field ' + getDisplayName(obj, name) + ' is not valid.');
            }
        };

        addValidation(target, name, validation);

    };
}

export function isNumber(msg?: string) {
    return  function (target: any, name: string) {

        const validation = {
            fieldName: name,
            validateFunction: (obj: any) => {
                // TODO: fix error: !obj[name] returns true if no value is provided by obj[name] and check for isNaN wont be executed
                let isValid = (!obj[name] || !isNaN(Number(obj[name])));
                let errorMEssage = 'Field ' + getDisplayName(obj, name) + ' is not a number';
                if (isValid && !Number.isInteger(Number(obj[name]))) {
                    isValid = false;
                    errorMEssage = 'Field ' + getDisplayName(obj, name) + ' should be integer value.';
                }
                return isValid ? undefined : (msg || errorMEssage);
            }
        };

        addValidation(target, name, validation);

    };
}

export function isNumberOrEmpty(msg?: string) {
    return  function (target: any, name: string) {

        const validation = {
            fieldName: name,
            validateFunction: (obj: any) => {
                // TODO: fix error: !obj[name] returns true if no value is provided by obj[name] and check for isNaN wont be executed
                const value = obj[name];
                if (value === null || value === undefined || value === '') {
                    return undefined;
                }
                let isValid = (!obj[name] || !isNaN(Number(obj[name])));
                let errorMEssage = 'Field ' + getDisplayName(obj, name) + ' is not a number';
                if (isValid && !Number.isInteger(Number(obj[name]))) {
                    isValid = false;
                    errorMEssage = 'Field ' + getDisplayName(obj, name) + ' should be integer value.';
                }
                return isValid ? undefined : (msg || errorMEssage);
            }
        };

        addValidation(target, name, validation);

    };
}

export function isFloatNumber(msg?: string) {
    return  function (target: any, name: string) {

        const validation = {
            fieldName: name,
            validateFunction: (obj: any) => {
                const value = Number(obj[name]);
                const isValid = isFloatNumberValid(value);
                const errorMEssage = 'Field ' + getDisplayName(obj, name) + ' should be with floating point.';
                return isValid ? undefined : (msg || errorMEssage);
            }
        };

        addValidation(target, name, validation);
    };
}

export function isNumberFractionalPartIsLessThanOrEqual(digits: number, msg?: string) {
    return  function (target: any, name: string) {
        const validation = {
            fieldName: name,
            validateFunction: (obj: any) => {
                const value = Number(obj[name]);
                const isValid = isNumberFractionalPartIsLessThanOrEqualValid(value, digits, true);

                let errorMessage = `Field ${getDisplayName(obj, name)} should have`;
                if (digits) {
                    errorMessage = errorMessage + ` ${digits} digits max after floating point.`;
                } else {
                    errorMessage = errorMessage + ' fractional part';
                }
                return isValid ? undefined : (msg || errorMessage);
            }
        };

        addValidation(target, name, validation);
    };
}

export function isDuration(msg?: string) {
    const regEx = /\d*:[0-5][0-9]$/;
    return  function (target: any, name: string) {

        const validation = {
            fieldName: name,
            validateFunction: (obj: any) => {
                const isValid = (!obj[name] || (regEx.test(obj[name])));
                return isValid ? undefined : (msg || 'Field ' + getDisplayName(obj, name) + ' should have format HH:MM');
            }
        };

        addValidation(target, name, validation);

    };
}

export function isEmail(msg?: string) {
    const regEx = /([\w.-]+@([\w-]+)\.+\w{2,})/;
    return  function (target: any, name: string) {

        const validation = {
            fieldName: name,
            validateFunction: (obj: any) => {
                const isValid = (!obj[name] || regEx.test(obj[name]));
                return isValid ? undefined : (msg || 'Field ' + getDisplayName(obj, name) + ' is not a valid email');
            }
        };

        addValidation(target, name, validation);
    };
}

export function displayName(displayName: string) {
    return  function (target: any, name: string) {
        const __displayName = camelCase('____displayName_', name);
        target[__displayName] = displayName;
    };
}

export function addDateTimeValidator(target: any, name: string, msg?: string, title?: string) {
    const regEx = /^\s*(3[01]|[12][0-9]|0?[1-9])\.(1[012]|0?[1-9])\.((?:19|20)\d{2})[ ](([0-1][0-9])|(2[0-3])):[0-5][0-9]$/;

    const validation: ValidatioRule = {
        fieldName: name,
        validateFunction: (obj: any) => {
            const isValid = (!obj[name] || (regEx.test(obj[name]) && DateTimeService.isValidDate(DateTimeService.parseUiDateTime(obj[name]))));
            return isValid ? undefined : (msg || 'Field ' + (title ? title : camelCase('', name)) + ' should have format DD.MM.YYYY HH:MM');
        }
    };

    addValidation(target, name, validation);
}

export function addDateValidator(target: any, name: string, msg?: string, title?: string) {
    const regEx = /^\s*(3[01]|[12][0-9]|0?[1-9])\.(1[012]|0?[1-9])\.((?:19|20)\d{2})\s*$/;

    const validation = {
        fieldName: name,
        validateFunction: (obj: any) => {
            const isValid = (!obj[name] || (regEx.test(obj[name]) && DateTimeService.isValidDate(DateTimeService.parseUiDate(obj[name]))));
            return isValid ? undefined : (msg || 'Field ' + (title ? title : camelCase('', name)) + ' should have format DD.MM.YYYY');
        }
    };

    addValidation(target, name, validation);
}

export function addNumberValidator(target: any, name: string, msg?: string, title?:string) {
    const validation = {
        fieldName: name,
        validateFunction: (obj: any) => {
            const isValid = (!obj[name] || !isNaN(Number(obj[name])));
            return isValid ? undefined : (msg || 'Field ' + (title ? title : camelCase('', name)) + ' is not a number');
        }
    };

    addValidation(target, name, validation);
}

export function addRequiredValidator(target: any, name: string, msg?: string, title?:string) {
    const validation = {
        fieldName: name,
        validateFunction: (obj: any) => {
            return obj[name] ? undefined : (msg || 'Field ' + (title ? title : camelCase('', name)) + ' cannot be empty');
        }
    };
    addValidation(target, name, validation);
}


const __computedFields = '__computedFields';

/*Logic*/
function addValidation(target: any, name: string, validationRule: ValidatioRule) {
    const __validators = '__validators';
    const __validationErrors = '__validationErrors';
    const __isValidForm = '__isValidForm';
    const __validateError = camelCase('__validateError_', name);
    const __errorFields = '__errorFields';

    if (!target.hasOwnProperty(__computedFields)) {
        const prototypeValue = target[__computedFields];
        Object.defineProperty(target, __computedFields, {
            configurable: true,
            enumerable: false,
            value: prototypeValue?.slice(0) ?? []
        });
    }

    if (!target.hasOwnProperty(__validators)) {
        const prototypeValue = target[__validators];
        Object.defineProperty(target, __validators, {
            configurable: true,
            enumerable: false,
            value: prototypeValue?.slice(0) ?? []
        });
    }

    if (!target.hasOwnProperty(__validationErrors)) {
        const descriptor = {
            configurable: true,
            enumerable: false,
            get: function getter(this: any) {
                let errorList: unknown[] = [];
                const validators = this[__validators];
                validators.forEach((validator: any) => {
                    const error = validator.validateFunction(this);
                    if (error) {
                        errorList.push(error);
                    }
                });
                return errorList;
            }
        };
        defineComputedProperty(target, __validationErrors, descriptor);
    }

    if (!target.hasOwnProperty(__isValidForm)) {
        const descriptor = {
            configurable: true,
            enumerable: false,
            get: function getter(this: any) {
                let isValid = true;
                const validators = this[__validators];
                if (!validators.length) return isValid;
                validators.forEach((validator: any) => {
                    const error = validator.validateFunction(this);
                    if (error) {
                        isValid = false;
                    }
                });
                return isValid;
            }
        };

        defineComputedProperty(target, __isValidForm, descriptor);
    }

    if (!target.hasOwnProperty(__validateError)) {
        const descriptor = {
            configurable: true,
            enumerable: false,
            get: function getter(this: any) {
                const validators = this[__validators];
                let errorList: any = [];
                validators.forEach((validator: any) => {
                    if (validator.fieldName === name) {
                        const error = validator.validateFunction(this);
                        if (error) {
                            errorList.push(error);
                        }
                    }
                });
                return errorList;
            }
        };
        defineComputedProperty(target, __validateError, descriptor);
    }

    if (!target.hasOwnProperty(__errorFields)) {
        const descriptor = {
            configurable: true,
            enumerable: false,
            get: function getter(this: any) {
                const validators = this[__validators];
                let errorNames: any = [];
                validators.forEach((validator: any) => {
                    const error = validator.validateFunction(this);
                    if (error) {
                        errorNames.push(validator.fieldName);
                    }
                });
                return errorNames;
            }
        };
        defineComputedProperty(target, __errorFields, descriptor);
    }

    target[__validators].push(validationRule);
}

function defineComputedProperty(target: any, name: string, descriptor: PropertyDescriptor & ThisType<any>) {
    Object.defineProperty(
        target,
        name,
        descriptor
    );
    const computedFields = target[__computedFields];
    computedFields.push(name);
}

function camelCase(prefix: string, others: string) {
    return prefix + others[0].toUpperCase() + others.substr(1);
}

export function getDisplayName<T>(target: T, name: keyof T) {
    const __displayName = camelCase('____displayName_', name as string);
    return target[__displayName as keyof T] || camelCase('', name as string);
}

export function setDisplayName(target: any, name: string, value: string) {
    const __displayName = camelCase('____displayName_', name);
    target[__displayName] = value;
}