import { makeObservable, observable, runInAction } from 'mobx';
import { apiClient, AttachmentConfigurationsModel } from "@Models";
import { FileFormat } from '@AppConstants';
class AttachmentConfigStore {
    @observable.deep config: AttachmentConfigurationsModel = {
        allowedAttachmentFileExtensions: [FileFormat.XLS, FileFormat.XLSX, FileFormat.DOC, FileFormat.DOCX, FileFormat.PDF, FileFormat.JPG, FileFormat.JPEG, FileFormat.PNG],
        excelAllowedAttachmentFileExtensions: [FileFormat.XLS, FileFormat.XLSX],
        wordAllowedAttachmentFileExtensions: [FileFormat.DOC, FileFormat.DOCX],
        pdfAllowedAttachmentFileExtensions: [FileFormat.PDF],
        imageAllowedAttachmentFileExtensions: [FileFormat.PNG, FileFormat.GIF, FileFormat.JPEG, FileFormat.JPG],
        archiveAllowedFileExtensions: [FileFormat.ZIP]
    };
    
    constructor() {
        makeObservable(this);
    }

    public loadData = async () => {
        var { data } = await apiClient.configurationAttachmentConfigurationsGet();
        runInAction(() => {
            this.config.allowedAttachmentFileExtensions = !!data.allowedAttachmentFileExtensions.length ? data.allowedAttachmentFileExtensions : this.config.allowedAttachmentFileExtensions;
            this.config.excelAllowedAttachmentFileExtensions = !!data.excelAllowedAttachmentFileExtensions.length ? data.excelAllowedAttachmentFileExtensions : this.config.excelAllowedAttachmentFileExtensions;
            this.config.wordAllowedAttachmentFileExtensions = !!data.wordAllowedAttachmentFileExtensions.length ? data.wordAllowedAttachmentFileExtensions : this.config.wordAllowedAttachmentFileExtensions;
            this.config.pdfAllowedAttachmentFileExtensions = !!data.pdfAllowedAttachmentFileExtensions.length ? data.pdfAllowedAttachmentFileExtensions : this.config.pdfAllowedAttachmentFileExtensions;
            this.config.imageAllowedAttachmentFileExtensions = !!data.imageAllowedAttachmentFileExtensions.length ? data.imageAllowedAttachmentFileExtensions : this.config.imageAllowedAttachmentFileExtensions;
            this.config.archiveAllowedFileExtensions = !!data.archiveAllowedFileExtensions.length ? data.archiveAllowedFileExtensions : this.config.archiveAllowedFileExtensions;
        });
    };
}

export const attachmentConfigStore = new AttachmentConfigStore();