import { computed, makeObservable, observable, runInAction } from 'mobx';
import { AppSecurityModel, UserProfile } from '@Models';
import { apiClient } from '../ApiClient';
import { appStore } from './AppStore';

export interface IWindowAdal extends Window {
    adal?: {
        redirectToLogin(): void;
        redirectToLogout(): void;
    };
}

export class AuthStore {
    @observable.deep appSecurityModel: AppSecurityModel = {
        canPrincipalSeeAdministration: false,
        canPrincipalSeeToolPanel: false,
        canPrincipalSeeSustainability: false,
        canPrincipalSeeProjects: false,
        canPrincipalManageProjects: false,
        canPrincipalSeeMatrices: false,
        canPrincipalManageMatrices: false,
        canPrincipalSeeLabsPart: false,
        canPrincipalSeeJobList: false,
        canPrincipalSeeHealthBoard: false,
        canPrincipalSeeStandards: false,
        canPrincipalManageStandards: false,
        canPrincipalSeeProducts: false,
        canPrincipalManageProducts: false,
        canPrincipalSeeProductCategories: false,
        canPrincipalManageProductCategories: false,
        canPrincipalSeeDesignParameters: false,
        canPrincipalManageDesignParameters: false,
        canPrincipalSeeTechnicalRatings: false,
        canPrincipalManageTechnicalRatings: false,
        canPrincipalSeeHomologations: false,
        canPrincipalManageHomologations: false,
        canPrincipalSeeCustomers: false,
        canPrincipalManageCustomers: false,
        canPrincipalSeeMarkets: false,
        canPrincipalManageMarkets: false,
        canPrincipalSeeTechnologyCenters: false,
        canPrincipalManageTechnologyCenters: false,
        canPrincipalSeeLabs: false,
        canPrincipalManageLabs: false,
        canPrincipalSeeApplicationMembers: false,
        canPrincipalImpersonate: false,
        canPrincipalCreateSecretProjects: false,
        canPrincipalSeeDoCView: false,
        canPrincipalSeeTestReportPart: false,
        canPrincipalSeeTestReportView: false,
        hasPrincipalAdminAccess: false,
        canPrincipalSeeComponents: false,
        canPrincipalManageComponents: false,
        canPrincipalSeeManufacturers: false,
        canPrincipalManageManufacturers: false,
	    canPrincipalSeeSProductFamilys: false,
        canPrincipalManageSProductFamilys: false,
        canPrincipalManageTestReports: false,
        canPrincipalManageDeclarationOfConformities: false,
        canPrincipalSeeProductDocuments: false,
        canPrincipalManageSProductFamilyVariants: false,
        canPrincipalManageeSProductFamilyMembers: false,
        canPrincipalReadExpiringSProductFamilyDocuments: false,
        canPrincipalSeeSustainabilityWorkingMode: false
    };

    constructor() {
        makeObservable(this);
    }

    private static _clearUserData() {
        localStorage.removeItem('currentUser');
        localStorage.removeItem('currentToken');
        localStorage.removeItem('impersonatedUser');
    }

    static logOut = () => {
        this._clearUserData();
        const appWindow: IWindowAdal = window;
        appWindow.adal && appWindow.adal.redirectToLogout();
    }

    static login = () => {
        this._clearUserData();
        const appWindow: IWindowAdal = window;
        appWindow.adal && appWindow.adal.redirectToLogin();
    }

    static logOutImpersonation = () => {
        localStorage.removeItem('impersonatedUser');
        window.location.href = '/';
    };

    static exitImpersonation = () => {
        AuthStore.logOutImpersonation();
    };

    get currentToken(): string {
        return (appStore.getValue<string>('currentToken') || '').replace(/"/gi, '');
    }

    get currentUser(): UserProfile | null {
        return appStore.getValue<UserProfile>('currentUser');
    }

    @computed
    get currentPrincipal() {
        return this.appSecurityModel.currentPrincipal;
    }

    public loadAppPermissions = async () => {
        const { data } = await apiClient.securityAppSettingsGet();
        runInAction(() => {
            this.appSecurityModel = data;
        });
    };

    @computed
    public get isUserHasAdminRole() {
        return !!this.impersonatedPrincipal || this.appSecurityModel.hasPrincipalAdminAccess;
    }

    public get impersonatedPrincipal(): IImpersonationModel | null {
        return appStore.getValue<IImpersonationModel>('impersonatedPrincipal');
    }

    isAuthorized(): boolean {
        return !!(this.currentToken && this.currentUser);
    }
}

export interface IImpersonationModel {
    principalId?: number;
    displayName?: string;
    email?: string;
    objectId?: string;
    roles?: Array<string>;
}

export const authStore = new AuthStore();
